import styled, { keyframes } from 'styled-components'

import { PageError as PageErrorComponent } from '@interco/affiliate-shopping-ui'

import { mediaQuery } from '../../../assets/styles/variables'

export const AffiliateStoreRedirect = styled.div`
  display: flex;
  justify-content: center;
  height: 80vh;
`

export const PageError = styled(PageErrorComponent)`
  margin: 3rem auto;
  align-items: center;

  @media ${mediaQuery.minMD} {
    margin: 12rem auto 3rem;
  }
`

export const PageErrorImg = styled.img`
  width: 10rem;
  margin-bottom: 1rem;

  @media ${mediaQuery.minMD} {
    width: 15.63rem;
    margin-bottom: 0;
  }
`

export const Content = styled.div`
  max-width: 90vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media ${mediaQuery.minMD} {
    max-width: 60vw;
  }
`

export const Title = styled.h1`
  text-align: center;
  font-size: 1.25rem;
  color: var(--gray500);
  font-weight: 600;
  margin-top: 3rem;
  margin-bottom: 0.5rem;
  font-family: 'Sora';

  span {
    font-size: 1.5rem;
  }

  @media ${mediaQuery.minMD} {
    font-size: 1.5rem;

    span {
      font-size: 2rem;
    }
  }
`

export const Description = styled.div`
  text-align: center;
  color: var(--gray500);
`

const barAnimation = keyframes`
  0% {
    background-position: 0px 0;
  }
  100% {
    background-position: 40px 0;
  }
`

export const ProgressBar = styled.div`
  background-image: linear-gradient(
    -45deg,
    var(--gray200) 25%,
    transparent 25%,
    transparent 50%,
    var(--gray200) 50%,
    var(--gray200) 75%,
    transparent 75%,
    transparent
  );
  background-size: 40px 40px;
  height: 100%;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  animation: ${barAnimation} 0.75s linear infinite;
  background-color: var(--gray500);
  height: 6px;
  border-radius: 2px;
  width: 100%;
  margin-top: 2.5rem;
`
