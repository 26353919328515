/**
 * General
 */

export const isBrowser = typeof window !== 'undefined'

/**
 * Local storage keys
 */

export const lsKeyFormData = 'afi-atletico-form-data'
export const lsKeyStoreInfoData = 'afi-atletico-selected-store-data'
