import React from 'react'

import { Helmet } from 'react-helmet'
import { useLocalStorage } from 'react-use'
import { Link, navigate } from 'gatsby'

import * as S from './AffiliateStoreRedirect.styles'
import CashbackIcon from '../../../assets/icons/cashback.svg'
import LayoutBlank from '../../LayoutBlank'
import LinkStoreRes from '../../../types/LinkStoreRes'
import { getRedirectLink } from '../../../api'
import useAsync from '../../../hooks/useAsync'
import SelectedStoreInfo from '../../../types/SelectedStoreInfo'
import { lsKeyFormData, lsKeyStoreInfoData } from '../../../utils/constants'
import RedirectLinkReq from '../../../types/RedirectLinkReq'
import FormData from '../../../types/FormData'
import { getAlphaNumerical } from '../../../utils/misc'

const defaultErrorTitle = 'Ops.. algo deu errado!'

const defaultErrorDescription =
  'Não foi possível ativar seu cashback. Por favor tente novamente mais tarde.'

const AffiliateStoreRedirect = () => {
  const storeLinkReq = useAsync<LinkStoreRes>()

  const [selectedStoreInfo] = useLocalStorage<SelectedStoreInfo>(lsKeyStoreInfoData)
  const [formData] = useLocalStorage<FormData>(lsKeyFormData)

  const generateLinkAndRedirect = async (data: RedirectLinkReq) => {
    try {
      const response = await storeLinkReq.run(getRedirectLink(data))
      setTimeout(() => {
        window.location.href = response.link
      }, 3000)
    } catch (error) {
      console.error({ error }) // eslint-disable-line no-console
    }
  }

  React.useEffect(() => {
    if (formData) {
      const { name, email, cpf, phone, bank, agency, account, allowAdvertising } = formData

      if (!name || !email || !cpf || !phone || !bank || !agency || !account) {
        navigate('/')
      } else {
        const nonAccHolderData: RedirectLinkReq = {
          storeId: selectedStoreInfo?.storeId || '',
          customerFullName: name,
          customerEmail: email,
          customerSocialNumber: getAlphaNumerical(cpf),
          customerPhoneNumber: phone,
          customerBankCodeOrRoutingNumber: bank,
          customerBankBranch: agency,
          customerBankAccount: account,
          customerAllowAdvertising: allowAdvertising,
          storeLink: selectedStoreInfo?.storeLink || '',
        }

        generateLinkAndRedirect(nonAccHolderData)
      }
    }
  }, [])

  return (
    <LayoutBlank>
      <Helmet
        meta={[
          {
            name: 'robots',
            content: 'noindex',
          },
        ]}
        title={
          selectedStoreInfo?.storeName
            ? `Redireciomento para ${selectedStoreInfo?.storeName}`
            : 'Redireciomento para loja'
        }
      />

      <S.AffiliateStoreRedirect>
        {storeLinkReq.isError ? (
          <S.PageError
            title={defaultErrorTitle}
            description={defaultErrorDescription}
            buttonUrl="/parceiros"
            buttonLabel="Ver todas as lojas"
            linkTag={Link}
          />
        ) : (
          <S.Content>
            <CashbackIcon width="4.5rem" height="4.5rem" strokeWidth="1" color="black" />
            <S.Title>
              Torcedor do Galo, <br />
              <span>Ativando seu cashback...</span>
            </S.Title>
            <S.Description>
              Você está sendo redirecionado ao site parceiro&nbsp;
              <strong>{selectedStoreInfo?.storeName}</strong>.
              <br />
              Agora é só fazer suas compras&nbsp;
              <strong>normalmente</strong>.
            </S.Description>
            <S.ProgressBar />
          </S.Content>
        )}
      </S.AffiliateStoreRedirect>
    </LayoutBlank>
  )
}

export default AffiliateStoreRedirect
