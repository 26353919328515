import * as React from 'react'

import { initTheme } from '@inter/inter-ui'

import * as S from './LayoutBlank.styles'

import '../../assets/styles/main.css'

interface LayoutBlankProps {
  children: React.ReactNode
}

const LayoutBlank = ({ children }: LayoutBlankProps) => {
  React.useLayoutEffect(() => {
    initTheme()
  }, [])

  return (
    <>
      <S.LayoutBlank>
        <div id="modal-area" />
        <S.Main>{children}</S.Main>
      </S.LayoutBlank>
    </>
  )
}

export default LayoutBlank
